<script setup>
import { useI18n } from 'vue-i18n';

import StaffLayout from '@/Layouts/StaffLayout.vue';
import Item from './Partials/Item.vue';

const { t } = useI18n();

const items = [
    {
        route: 'staff.management.salary-notification',
        name: t('Send salary notification to temporary workers',),
        description: t('When you send this notification all temp workers that have worked in the previous 7 days will receive a notification that their salary has been paid.'),
    }
];
</script>

<template>
    <StaffLayout :title="$t('Management')">
        <h1 class="mb-4 text-[32px] font-bold">{{ $t('Management') }}</h1>
        <div class="w-full">
            <div class="grid">
                <div>
                    <template v-for="(item, index) in items" :key="index">
                        <Item :item="item" :borderBottom="(index + 1) === items.length" />
                    </template>
                </div>
            </div>
        </div>
    </StaffLayout>
</template>
